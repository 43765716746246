import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import {
	CanMakePaymentResult,
	loadStripe,
	PaymentRequest,
	PaymentRequestUpdateOptions,
} from '@stripe/stripe-js'
import React, { useCallback, useState } from 'react'

export function StripeProvider(props: { children: React.ReactNode }) {
	const [stripePromise] = useState(() =>
		loadStripe(String(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY))
	)
	return <Elements stripe={stripePromise}>{props.children}</Elements>
}

export enum PaymentStatus {
	Idle = 'Idle',
	Checking = 'Checking',
	Ready = 'Ready',
	Working = 'Working',
	Success = 'Success',
	Canceled = 'Canceled',
	Failure = 'Failure',
}

export type PaymentButtonProvider = 'applePay' | 'googlePay'

export type ClientSecretLoader = () => Promise<
	{ clientSecret: string | null } | { error?: unknown }
>

export function useStripePayment() {
	const stripe = useStripe()
	const elements = useElements()
	const [status, setStatus] = useState<PaymentStatus>(PaymentStatus.Idle)
	const [cardError, setCardError] = useState<null | string>(null)
	const [paymentRequest, setPaymentRequest] = useState<null | PaymentRequest>(null)
	const [canMakePayment, setCanMakePayment] = useState<null | false | CanMakePaymentResult>(null)

	React.useEffect(() => {
		setStatus(PaymentStatus.Checking)
		if (stripe) {
			const pr = stripe.paymentRequest({
				currency: 'usd',
				total: {
					amount: 1,
					label: 'temp',
				},
				country: 'CZ',
			})

			setPaymentRequest(pr)

			pr.canMakePayment().then(
				(result) => {
					setStatus(PaymentStatus.Ready)
					setCanMakePayment(result ?? false)
				},
				() => {
					setStatus(PaymentStatus.Ready)
					setCanMakePayment(false)
				}
			)

			const handleCancel = () => {
				setStatus(PaymentStatus.Ready)
			}

			pr.on('cancel', handleCancel)

			return () => {
				pr.off('cancel', handleCancel)
			}
		}
	}, [stripe])

	const openPaymentDialog = useCallback(
		(options: PaymentRequestUpdateOptions, clientSecretLoader: ClientSecretLoader) => {
			if (paymentRequest) {
				paymentRequest.update(options)
				paymentRequest.once('paymentmethod', async (e) => {
					if (!stripe) {
						throw new Error('Missing Stripe')
					}
					setStatus(PaymentStatus.Working)
					try {
						const data = await clientSecretLoader()
						if (!('clientSecret' in data)) {
							console.error(data)
							e.complete('fail')
							return
						}

						const { clientSecret } = data
						if (!clientSecret) {
							e.complete('fail')
							setStatus(PaymentStatus.Failure)
							return
						}

						const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
							clientSecret,
							{ payment_method: e.paymentMethod.id },
							{ handleActions: false }
						)

						if (confirmError) {
							e.complete('fail')
						} else {
							e.complete('success')
							if (paymentIntent?.status === 'requires_action') {
								const { error } = await stripe.confirmCardPayment(clientSecret)
								if (error) {
									setStatus(PaymentStatus.Failure)
								} else {
									setStatus(PaymentStatus.Success)
								}
							} else {
								setStatus(PaymentStatus.Success)
							}
						}
					} catch (error) {
						e.complete('fail')
					}
				})
				paymentRequest.show()
			}
		},
		[paymentRequest, stripe]
	)

	const finishWithCard = useCallback(
		async (clientSecretLoader: ClientSecretLoader) => {
			if (!elements || !stripe) {
				throw new Error('Missing Stripe')
			}
			setStatus(PaymentStatus.Working)

			const card = elements.getElement(CardElement)

			if (card) {
				const data = await clientSecretLoader()
				if (!('clientSecret' in data)) {
					console.error(data)
					setStatus(PaymentStatus.Failure)
					setCardError('Něco se nezdařilo')
					return
				}

				const { clientSecret } = data

				if (clientSecret) {
					const result = await stripe.confirmCardPayment(clientSecret, {
						payment_method: {
							card,
						},
					})
					if (result.error) {
						setStatus(PaymentStatus.Failure)
						switch (result.error.code) {
							case 'incomplete_number':
								setCardError('Vyplň prosím platební údaje.')
								break
							default:
								setCardError('Něco se nezdařilo')
						}
						return
					} else {
						if (result.paymentIntent.status === 'succeeded') {
							setStatus(PaymentStatus.Success)
							return
						}
					}
				}
			} else {
				setStatus(PaymentStatus.Failure)
				setCardError('Něco se nezdařilo')
			}
		},
		[elements, stripe]
	)

	return {
		status,
		cardError,
		canMakePayment,
		finishWithCard,
		openPaymentDialog,
	} as const
}

import React, { useCallback, useState } from 'react'
import { useStripePayment } from '../../libs/stripe/StripeProvider'
import type { IconName } from './Icon'
import type { PaymentBoxMetadata } from './PaymentBox'
import { usePaymentIntent } from './usePaymentIntent'

export const allProviders: Array<{
	id: 'applePay' | 'googlePay'
	iconName: IconName
	label: string
}> = [
	{ id: 'applePay', iconName: 'applePay', label: 'Apple Pay' },
	{ id: 'googlePay', iconName: 'googlePay', label: 'Google Pay' },
]

export type Provider = typeof allProviders[number]

export function usePaymentBox({
	value,
	finalValue,
	label,
	metadata,
}: {
	value: number
	finalValue: number
	label: string
	metadata: PaymentBoxMetadata
}) {
	const [paymentIntentId, setPaymentIntentId] = useState<null | string>(null)
	const loadPaymentIntent = usePaymentIntent({
		value,
		metadata,
	})

	const clientSecretLoader = useCallback(
		() =>
			loadPaymentIntent(finalValue * 100).then((intent) => {
				if ('paymentIntentId' in intent) {
					setPaymentIntentId(intent.paymentIntentId)
				}
				return intent
			}),
		[finalValue, loadPaymentIntent]
	)

	const { status, canMakePayment, cardError, finishWithCard, openPaymentDialog } =
		useStripePayment()

	const providers = React.useMemo(() => {
		return allProviders.map((p) => {
			return {
				...p,
				available: Boolean(canMakePayment ? canMakePayment[p.id] : false),
			}
		})
	}, [canMakePayment])

	const availableProviders = React.useMemo(() => {
		return providers.filter((p) => p.available)
	}, [providers])

	return {
		status,
		providers: availableProviders,
		canMakePayment,
		cardError,
		paymentIntentId,
		finishWithCard: useCallback(async () => {
			finishWithCard(clientSecretLoader)
		}, [clientSecretLoader, finishWithCard]),
		openPaymentDialog: useCallback(async () => {
			openPaymentDialog(
				{
					currency: 'usd',
					total: {
						amount: finalValue * 100,
						label,
					},
				},
				clientSecretLoader
			)
		}, [clientSecretLoader, finalValue, label, openPaymentDialog]),
	}
}

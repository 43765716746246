import { useRouter } from 'next/router'
import { CardElement } from '@stripe/react-stripe-js'
import type { StripeCardElementOptions } from '@stripe/stripe-js'
import clsx from 'clsx'
import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { PaymentBoxAmountType, PaymentBoxDescriptionType } from '../../generated/content'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import { PaymentStatus } from '../../libs/stripe/StripeProvider'
import { filterNonEmpty } from '../../libs/utils/filterNonEmpty'
import type { AuthorResult } from '../data/fragments/AuthorFragment'
import type { PaymentBoxResult } from '../data/fragments/PaymentBoxFragment'
import { assertNever } from '../utils/assertNevet'
import { Button } from './Button'
import { Icon } from './Icon'
import { Image } from './Image'
import { ImgixImage } from './ImgixImage'
import { Link } from './Link'
import style from './PaymentBox.module.sass'
import type { Provider } from './usePaymentBox'
import { usePaymentBox } from './usePaymentBox'
import type { API } from '../../pages/api/auth/[handler]'

function useRefresh() {
	const router = useRouter()
	const ref = React.useRef(router)
	ref.current = router

	return React.useCallback(() => {
		ref.current.push(ref.current.asPath)
	}, [])
}

export type PaymentBoxProps = PaymentBoxResult & {
	metadata: PaymentBoxMetadata
	authors?: AuthorResult[]
	lockedArticle?: boolean
}

export type PaymentBoxMetadata = {
	paymentBoxId: string
	articleId: string
}

const api = createFetcher<API>('/api/auth/[handler]')

export const PaymentBox: FunctionComponent<PaymentBoxProps> = ({
	metadata,
	title,
	text,
	amountTitle,
	amountType,
	basePrice,
	descriptionType,
	authors,
	multiplier1,
	multiplier2,
	multiplier3,
	icon,
	thingIcon,
	lockedArticle,
	hideCustomPrice,
}) => {
	const refresh = useRefresh()
	const [showCardPayment, setShowCardPayment] = useState(false)
	const [value, setValue] = useState(multiplier1 ?? 1)
	const [rawValue, setRawValue] = useState(`${value}`)
	const finalValue = useMemo(() => Math.ceil(value * (basePrice ?? 1)), [basePrice, value])

	const label =
		amountType === PaymentBoxAmountType.things ? `${value} × ${amountTitle}` : 'Dar pro FOMO'

	const { status, providers, cardError, finishWithCard, openPaymentDialog, paymentIntentId } =
		usePaymentBox({
			value,
			finalValue,
			label,
			metadata,
		})

	React.useEffect(() => {
		if (status === PaymentStatus.Success && lockedArticle && paymentIntentId) {
			api('articleDonation', {
				articleId: metadata.articleId,
				paymentIntentId,
			}).then((result) => {
				if (result.status === 'ok') {
					refresh()
				} else {
					console.error(result)
					alert('Něco se nezdařilo')
				}
			})
		}
	}, [lockedArticle, metadata.articleId, paymentIntentId, refresh, status])

	const multipliers = useMemo(
		() =>
			[
				{
					id: 1,
					value: multiplier1,
				},
				{
					id: 2,
					value: multiplier2,
				},
				{
					id: 3,
					value: multiplier3,
				},
			]
				.map((multipler) => ({
					...multipler,
					value: multipler.value ?? 0,
					isActive: multipler.value === value,
				}))
				.filter((multiplier) => multiplier.value ?? 0 > 0),
		[multiplier1, multiplier2, multiplier3, value]
	)

	const [_submits, setSubmits] = useState(0)

	const [canPayByCard, setCanPayByCard] = useState(false)
	const [invalidCardError, setInvalidCardError] = useState('')

	const onSubmit = useCallback<React.FormEventHandler<HTMLFormElement>>(
		(e) => {
			e.preventDefault()
			setSubmits((s) => s + 1)
			setInvalidCardError('')

			if (showCardPayment) {
				if (canPayByCard) {
					finishWithCard()
				} else {
					setInvalidCardError('Vyplň prosím platební údaje.')
				}
			} else if (providers.length > 0) {
				openPaymentDialog()
			} else {
				setShowCardPayment(true)
			}
		},
		[canPayByCard, finishWithCard, openPaymentDialog, providers.length, showCardPayment]
	)

	const checking = [PaymentStatus.Idle, PaymentStatus.Checking].includes(status)

	const working = [
		PaymentStatus.Idle,
		PaymentStatus.Checking,
		PaymentStatus.Working,
		PaymentStatus.Success,
	].includes(status)

	const disabled =
		working ||
		[
			PaymentStatus.Idle,
			PaymentStatus.Checking,
			PaymentStatus.Working,
			PaymentStatus.Success,
		].includes(status)

	const priceTag = useMemo(() => {
		return finalValue.toLocaleString('en-US', {
			style: 'currency',
			currency: 'usd',
			minimumFractionDigits: 0,
		})
	}, [finalValue])

	const handleValidChange = useCallback((info: { valid: boolean; blurred: boolean }) => {
		setCanPayByCard(info.valid)
	}, [])

	return (
		<section className={clsx(style.wrapper, lockedArticle && style.locked)}>
			{!lockedArticle && descriptionType !== PaymentBoxDescriptionType.none && (
				<div className={style.info}>
					<div className={style.infoIn}>
						{descriptionType === PaymentBoxDescriptionType.text ? (
							<>
								{icon && (
									<Image src={icon.url} width={icon.width} height={icon.height} alt={icon.alt} />
								)}
								<h3 className={style.infoTitle}>{title}</h3>
								<p className={style.infoText}>{text}</p>
							</>
						) : descriptionType === PaymentBoxDescriptionType.author ? (
							authors && <PaymentBoxAuthors authors={authors} />
						) : (
							assertNever(descriptionType)
						)}
					</div>
				</div>
			)}
			<form className={style.form} onSubmit={onSubmit}>
				<fieldset
					className={clsx(style.formFieldset, working && style.working)}
					disabled={disabled}>
					<div className={style.formIn}>
						<h4 className={style.formTitle}>{amountTitle}</h4>
						<div className={style.formPicker}>
							{amountType === PaymentBoxAmountType.things && thingIcon && (
								<div className={style.thingIcon}>
									<Image
										src={thingIcon.url}
										width={thingIcon.width}
										height={thingIcon.height}
										alt={thingIcon.alt}
									/>
								</div>
							)}
							{!hideCustomPrice && (
								<input
									type="number"
									min={1}
									step={1}
									value={rawValue}
									onChange={(event) => {
										setRawValue(event.target.value)
										setValue(Math.ceil(parseFloat(event.target.value) || 1))
									}}
									onBlur={() => {
										setRawValue(`${value}`)
									}}
									className={style.customValue}
								/>
							)}
							{multipliers.map((multiplier) => (
								<button
									type="button"
									key={multiplier.value}
									className={clsx(style.predefinedAmount, multiplier.isActive && style.is_active)}
									onClick={() => {
										setValue(multiplier.value)
										setRawValue(`${multiplier.value}`)
									}}>
									{amountType === PaymentBoxAmountType.money && '$'}
									{multiplier.value}
								</button>
							))}
						</div>

						{showCardPayment ? (
							<CardPayment
								onValidChange={handleValidChange}
								errorMessage={cardError || invalidCardError}
								buttonLabel={
									<>
										{working ? 'Platba probíhá' : 'Zaplatit kartou'} {priceTag}
									</>
								}
							/>
						) : (
							<div className={style.submit}>
								<Button type="submit" view="highlight" name="provider" value="any">
									Podpoř tvůrce ({priceTag})
								</Button>
							</div>
						)}

						{providers.length > 0 ? (
							<div className={style.providers}>
								<span className={style.providersLabel}>Secure payment</span>
								<div className={style.providersItems}>
									{providers.map((provider) => (
										<ProviderIcon key={provider.id} provider={provider} />
									))}
								</div>
							</div>
						) : (
							<div className={style.providers}>
								<span className={style.providersLabel}>Secure card payment by Stripe</span>
							</div>
						)}
					</div>
				</fieldset>
				{checking && (
					<div className={style.providersNote}>Zjištujeme, jestli tvůj prohlížeč umí platby.</div>
				)}
				{status === PaymentStatus.Success && <div className={style.providersNote}>Díky!</div>}
				{status === PaymentStatus.Working && (
					<div className={style.providersNote}>Probíhá platba…</div>
				)}
			</form>
		</section>
	)
}

const ProviderIcon: FunctionComponent<{ provider: Provider & { available: boolean } }> = ({
	provider,
}) => {
	const content = (
		<span className={style.providerIcon}>
			<Icon name={provider.iconName} />
		</span>
	)

	if (provider.available) {
		return (
			<button
				type="submit"
				className={clsx(style.provider, style.available)}
				aria-label={provider.label}
				name="provider"
				value={provider.id}>
				{content}
			</button>
		)
	}

	return <span className={style.provider}>{content}</span>
}

const PaymentBoxAuthors: FunctionComponent<{ authors: AuthorResult[] }> = ({ authors }) => {
	if (authors.length === 1) {
		const author = authors[0]
		return author && author.url ? (
			<Link className={style.author} url={author.url}>
				{author.avatar && (
					<div className={style.authorAvatar}>
						<ImgixImage
							src={author.avatar.url}
							layout="fill"
							alt={author.avatar.alt ?? ''}
							sizes="88px"
						/>
					</div>
				)}
				{author.name && <h3 className={style.authorName}>{author.name}</h3>}
				{author.description && <p className={style.authorDescription}>{author.description}</p>}
			</Link>
		) : null
	}

	return (
		<div className={style.authors}>
			<div className={style.authorsAvatars}>
				{authors
					.map((a) => a.avatar)
					.filter(filterNonEmpty)
					.map((avatar, n) => (
						<React.Fragment key={n}>
							{n > 0 && <div className={style.authorsAvatarSep}>+</div>}
							<div className={style.authorAvatar}>
								<ImgixImage src={avatar.url} layout="fill" alt={avatar.alt ?? ''} sizes="88px" />
							</div>
						</React.Fragment>
					))}
			</div>
			<div className={style.authorsNote}>
				<h3 className={style.authorName}>{authors.map((a) => a.name).join(' a ')}</h3>
			</div>
		</div>
	)
}

export default function CardPayment(props: {
	errorMessage?: string | null
	buttonLabel: React.ReactNode
	onValidChange: (info: { valid: boolean; blurred: boolean }) => void
}) {
	const [valid, setValid] = useState<null | boolean>(null)
	const [blurred, setBlurred] = useState(false)
	const { onValidChange, buttonLabel, errorMessage } = props

	useEffect(() => {
		onValidChange({ valid: Boolean(valid), blurred })
	}, [valid, blurred, onValidChange])

	const options = useMemo<StripeCardElementOptions>(() => {
		return {
			style: {
				base: {
					color: '#32325d',
					fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
					fontSmoothing: 'antialiased',
					fontSize: '16px',
					'::placeholder': {
						color: '#aab7c4',
					},
					backgroundColor: '#fafafa',
					padding: '.5em',
				},
				invalid: {
					color: '#fa755a',
					iconColor: '#fa755a',
				},
			},
		}
	}, [])

	return (
		<div className={style.CardPayment}>
			{errorMessage && (
				<div className={style.CardError}>
					<p>{errorMessage}</p>
				</div>
			)}
			<div className={style.CardElement}>
				<CardElement
					options={options}
					onChange={(e) => {
						setValid(e.complete)
					}}
					onBlur={() => {
						setBlurred(true)
					}}
				/>
			</div>
			<div className={style.CardButton}>
				<Button type="submit" view="highlight" name="provider" value="any">
					{buttonLabel}
				</Button>
			</div>
		</div>
	)
}

import React, { useCallback } from 'react'
import { createFetcher } from '../../libs/next/api/jsonApiHandler'
import type { API } from '../../pages/api/stripe/[handler]'
import type { PaymentBoxMetadata } from './PaymentBox'

export function usePaymentIntent({
	value,
	metadata: { articleId, paymentBoxId },
}: {
	value: number
	metadata: PaymentBoxMetadata
}) {
	const stripeApi = React.useMemo(() => createFetcher<API>('/api/stripe/[handler]'), [])

	const loadPaymentIntent = useCallback(
		async (cents: number) => {
			return stripeApi('paymentBox', {
				amount: value,
				cents,
				articleId,
				paymentBoxId,
			}).then(
				(data) => ({
					clientSecret: data.paymentIntent.client_secret,
					paymentIntentId: data.paymentIntent.id,
				}),
				(error) => {
					return {
						error,
					}
				}
			)
		},
		[articleId, paymentBoxId, stripeApi, value]
	)

	return loadPaymentIntent
}
